import React from 'react'
import { graphql } from 'gatsby'

import Layout from '../components/layout'
import './imprint.scss'

const ImprintPage = ({ pageContext: { locale }, ...props }) => {  
  return(<Layout>
    <div className="imprint-wrapper" dangerouslySetInnerHTML={{ __html: props.data.markdown.edges[0].node.html}}>
    </div>
  </Layout>
)
}
  

export default ImprintPage

export const ImprintQuery = graphql`
query ImprintQuery ($locale: String!) {
  markdown: allMarkdownRemark(filter: { frontmatter: { locale: { eq: $locale }}}) {
    edges {
      node {
        html,
        frontmatter {
          locale
        }
      }
    }
  }
}
`